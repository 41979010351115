<template>
  <div>
    <v-dialog
      v-model="isComposeDialogVisible"
      width="700"
      :fullscreen="isCidadeComposeDialogVisible"
      hide-overlay
      content-class="email-compose-dialog ms-md-auto"
      transition="slide-y-reverse-transition"
    >
      <template #activator="{ on, attrs }">
        <div class="pa-1">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            :loading="loadingCidades[`${estadoItem.uuid}`]"
            @click="buscarCidades(estadoItem.uuid)"
          >
            <v-icon class="actions"> mdi-home-city </v-icon>
          </v-btn>
        </div>
      </template>
      <cidade-compose-content
        :is-dialog-fullscreen.sync="isCidadeComposeDialogFullscreen"
        :estadoItem="estadoItem"
        :cidades="dataCidades"
        :isLoadingTable="isLoadingTable"
        @close-dialog="isComposeDialogVisible = false"
      ></cidade-compose-content>
    </v-dialog>
  </div>
</template>
  
  <script>
import { ref } from "@vue/composition-api";
import CidadeComposeContent from "./CidadeComposeContent.vue";
import store from "@/store";

export default {
  components: {
    CidadeComposeContent,
  },
  props: {
    isCidadeComposeDialogVisible: {
      type: Boolean,
      required: true,
    },
    estadoItem: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const isComposeDialogVisible = ref(false);
    const isDialogLoadVisible = ref(false);
    const isCidadeComposeDialogFullscreen = ref(false);
    const dataCidades = ref([]);
    const loadingCidades = ref([]);
    const isLoadingTable = ref(false);

    function buscarCidades(uuid) {
      loadingCidades.value[uuid] = true;
      isDialogLoadVisible.value = true;
      isLoadingTable.value = true;
      let url = `/estados/${uuid}`;
      store
        .dispatch("resDefinicoes/show", url)
        .then((response) => {
          dataCidades.value = response.cidades;
        })
        .catch(() => {
          store.dispatch("snackBarModule/openSnackBar", {
            color: "danger",
            timeout: 3000,
            text: "Houve um erro ao buscar cidades deste estado.",
          });
        })
        .finally(() => {
          loadingCidades.value[uuid] = false;
          isDialogLoadVisible.value = false;
          isLoadingTable.value = false;
        });
    }

    return {
      isComposeDialogVisible,
      isCidadeComposeDialogFullscreen,
      isDialogLoadVisible,
      dataCidades,
      loadingCidades,
      isLoadingTable,
      buscarCidades,
    };
  },
};
</script>
  
  <style lang="scss">
@import "~@core/preset/preset/mixins.scss";
</style>
  