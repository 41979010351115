import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"width":"700","fullscreen":_vm.isCidadeComposeDialogVisible,"hide-overlay":"","content-class":"email-compose-dialog ms-md-auto","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"pa-1"},[_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.loadingCidades[("" + (_vm.estadoItem.uuid))]},on:{"click":function($event){return _vm.buscarCidades(_vm.estadoItem.uuid)}}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"actions"},[_vm._v(" mdi-home-city ")])],1)],1)]}}]),model:{value:(_vm.isComposeDialogVisible),callback:function ($$v) {_vm.isComposeDialogVisible=$$v},expression:"isComposeDialogVisible"}},[_c('cidade-compose-content',{attrs:{"is-dialog-fullscreen":_vm.isCidadeComposeDialogFullscreen,"estadoItem":_vm.estadoItem,"cidades":_vm.dataCidades,"isLoadingTable":_vm.isLoadingTable},on:{"update:isDialogFullscreen":function($event){_vm.isCidadeComposeDialogFullscreen=$event},"update:is-dialog-fullscreen":function($event){_vm.isCidadeComposeDialogFullscreen=$event},"close-dialog":function($event){_vm.isComposeDialogVisible = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }