<template>
  <v-row>
    <v-col cols="12">
      <crud
        :title="'Gestão de estados'"
        buttonAddItemText="Novo item"
        :tamanhoModalCrud="500"
        searchLabelText="Pesquisar na lista"
        :formTitle="nameForm"
        :callBackModalCancelar="fechar"
        :dialog.sync="dialog"
        :callBackModalSalvar="salvar"
      >
        <template slot="data-table">
          <v-data-table
            :headers="headers"
            :items="data.estados"
            :loading="loadingGeral"
            class="table-kitchen-sink"
            items-per-page-text="Linhas por página"
            no-results-text="Nenhum registro encontrado"
            loading-text="Carregando... Por favor, espere"
            :footer-props="{
              showFirstLastPage: true,
              'items-per-page-text': 'Linhas por página',
            }"
            mobile-breakpoint="0"
          >
            <!-- ACTIONS -->
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <!-- Visualizar -->
                <v-list v-if="$can('view', 'Cliente')">
                  <v-list-item @click="buscarCidades(item.uuid)">
                    <v-list-item-title>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <cidades-component 
                            v-bind="attrs" 
                            v-on="on" 
                            :isCidadeComposeDialogVisible="true" 
                            :estadoItem="item"
                            >
                          </cidades-component>
                        </template>
                        <span>Cidades</span>
                      </v-tooltip>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-list v-if="$can('view', 'Cliente')">
                  <v-list-item>
                    <v-list-item-title>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon
                              class="actions"
                              @click="toggleModalLateral(item)"
                            >
                              mdi-eye
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Visualizar</span>
                      </v-tooltip>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <!-- Editar -->
                <v-list v-if="$can('edit', 'Cliente')">
                  <v-list-item>
                    <v-list-item-title>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on" :loading="loadings.editar[`${item.id}`]">
                            <v-icon class="actions" @click="editar(item)">
                              mdi-pencil
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <!-- Deletar -->
                <v-list v-if="$can('delete', 'Cliente')">
                  <v-list-item>
                    <v-list-item-title>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on" :loading="loadings.excluir[`${item.id}`]">
                            <v-icon
                              class="actions"
                              @click="remover(item, true)"
                            >
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Deletar</span>
                      </v-tooltip>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{ item.created_at | dateFormatBr() }}
            </template>
          </v-data-table>
        </template>
        <template slot="contentModalCrud">
          <v-container fluid>
            <v-row class="ma-3" wrap>
              <!-- Nome -->
              <v-col cols="12" sm="12">
                <v-text-field
                  ref="nome"
                  label="Nome"
                  placeholder="Escreva o nome do estado"
                  required
                  outlined
                  dense
                  v-model="estado.nome"
                  data-vv-as="nome"
                  persistent-hint
                  :hint="errors.first('nome')"
                  :error="errors.collect('nome').length ? true : false"
                  v-validate="'required|min:3'"
                  data-vv-validate-on="change"
                  id="nome"
                  name="nome"
                ></v-text-field>
              </v-col>
              <!-- UF -->
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  ref="sigla"
                  label="Sigla"
                  placeholder="Escreva a sigla do estado"
                  required
                  outlined
                  dense
                  v-model="estado.uf"
                  data-vv-as="uf"
                  persistent-hint
                  :hint="errors.first('uf')"
                  :error="errors.collect('uf').length ? true : false"
                  v-validate="'required|length:2'"
                  data-vv-validate-on="change"
                  id="uf"
                  name="uf"
                  maxlength="2"
                  counter="2"
                ></v-text-field>
              </v-col>
              <!-- IBGE -->
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  ref="ibge"
                  label="IBGE"
                  placeholder="Escreva o código do estado"
                  required
                  outlined
                  dense
                  v-model="estado.ibge"
                  data-vv-as="ibge"
                  persistent-hint
                  :hint="errors.first('ibge')"
                  :error="errors.collect('ibge').length ? true : false"
                  v-validate="'required'"
                  data-vv-validate-on="change"
                  id="ibge"
                  name="ibge"
                  type="number"
                ></v-text-field>
              </v-col>
              <!-- SL -->
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  ref="sl"
                  label="SL"
                  placeholder="Escreva o sl do estado"
                  required
                  outlined
                  dense
                  v-model="estado.sl"
                  data-vv-as="sl"
                  persistent-hint
                  :hint="errors.first('sl')"
                  :error="errors.collect('sl').length ? true : false"
                  v-validate="'required'"
                  data-vv-validate-on="change"
                  id="sl"
                  name="sl"
                  type="number"
                ></v-text-field>
              </v-col>
              <!-- DDD -->
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  ref="ddd"
                  label="DDD"
                  placeholder="Escreva o código do estado"
                  required
                  outlined
                  dense
                  v-model="estado.ddd"
                  data-vv-as="ddd"
                  persistent-hint
                  :hint="errors.first('ddd')"
                  :error="errors.collect('ddd').length ? true : false"
                  v-validate="'required'"
                  data-vv-validate-on="change"
                  id="ddd"
                  name="ddd"                  
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <!-- ------------- MODAL DE DELETE --------------------------------------------- -->
        <template slot="modalDelete/titleModal">Excluir o registro?</template>
        <template slot="modalDelete/contentModal">
          <p>
            Atenção! Você está prestes a realizar uma ação que não pode ser
            desfeita.
          </p>
          <p>Você realmente deseja excluir esse registro?</p>
        </template>
        <template slot="modalDelete/actionsModal">
          <v-btn depressed>Fechar</v-btn>
          <v-btn color="error">Excluir</v-btn>
          <v-spacer class="d-none d-md-block"></v-spacer>
        </template>
      </crud>
    </v-col>
  </v-row>
</template>
<script>
import store from "@/store";
import {
  onUnmounted,
  onMounted,
  ref,
  reactive,
  computed,
  nextTick,
  watch,
  onUpdated,
} from "@vue/composition-api";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import Crud from "@/components/Crud.vue";
import ModalLateral from "@/components/ModalLateral.vue";
import CidadesComponent from "@/modules/administrativo/modules/configuracoes/modules/components/Cidades.vue"

export default {
  name: "Definicoes-res",
  inject: ["$validator"],
  components: {
    // 3rd Party
    PerfectScrollbar,
    Crud,
    ModalLateral,
    CidadesComponent
  },
  setup() {
    onMounted(() => {});
    const modalLateral = ref({
      show: false,
      item: [],
      title: "",
    });
    const headersModal = ref(
      {
        text: "Nome",
        value: "name",
      },
      {
        text: "Local",
        value: "guard_name",
      },
      {
        text: "Permissões",
        value: "permissoes",
      },
      {
        text: "Data de criação",
        value: "created_at",
      },
      {
        text: "Última alteração",
        value: "updated_at",
      }
    );
    const toggleModalLateral = ref();
    const headers = ref([
      { text: "Nome", value: "nome" },
      { text: "Sigla", value: "uf" },
      { text: "DDD", value: "ddd" },
      { text: "IBGE", value: "ibge" },
      { text: "Data de criação", value: "created_at" },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ]);
    const estado = ref({
      nome: "", uf: "", ibge: "", sl: "", ddd: ""
    }); 
    const dialog = ref(false) 
    const indexEdicao = ref(-1) 
    const loadings = ref({
      editar: [],
      excluir: [],
      cidades: []
    });
    const isComposeDialogVisible = ref(false)
    const nameForm = ref("Novo estado")
    const dadosModulo = ref({
      url: "estados/initialize",
      set: "setDataEstados",
      method: {
        post: "POST",
        put: "PUT"
      },
      post: "/post",
      put: "/estados/",
      sucesso: {
        salvar: "Estado cadastrado com sucesso.",
        atualizar: "Estado atualizado com sucesso."
      },
      erro :{
        salvar: "Erro ao salvar estado.",
        atualizar: "Erro ao atualizar estado."
      }       
    });
    onMounted(() => {
      store.dispatch("resDefinicoes/initialize", dadosModulo);
    });
    const data = computed(
      () => store.getters["resDefinicoes/getDataEstadosCidades"]
    );
    const loadingGeral = computed(
      () => store.getters["resDefinicoes/getLoadingEstadosCidades"]
    );
    function salvar()
    {
      this.$validator.validateAll().then((result) => {
        if (result) {
          dialog.value = false;                   
          let sucesso =
            indexEdicao.value === -1
              ? dadosModulo.value.sucesso.salvar
              : dadosModulo.value.sucesso.atualizar;
          let erro =
            indexEdicao.value === -1
              ? dadosModulo.value.erro.salvar
              : dadosModulo.value.erro.atualizar;
          let estadoItem = estado.value;
          estadoItem._method = indexEdicao.value === -1 ? dadosModulo.value.method.post : dadosModulo.value.method.put;
          estadoItem.url = indexEdicao.value === -1 ? dadosModulo.value.post : dadosModulo.value.put + estado.value.uuid;
          
          store
            .dispatch("resDefinicoes/salvar", estadoItem)
            .then((response) => {
              if (response === true) {
                indexEdicao.value === -1
                  ? data.value.estados.push(estadoItem)
                  : Object.assign(data.value.estados[indexEdicao.value], data.value.estados);
                  store.dispatch("snackBarModule/openSnackBar", {
                    color: "success",
                    timeout: 3000,
                    text: sucesso,
                  });
              }
            })
            .catch((error) => {
              console.log(error);
              store.dispatch("snackBarModule/openSnackBar", {
                  color: "danger",
                  timeout: 3000,
                  text: erro,
                });
            })
            .finally(() => {
              fechar();
            });
        } else {
          store.dispatch("snackBarModule/openSnackBar", {
            color: "danger",
            timeout: 3000,
            text: "Preencha todo formulário antes de salvar!",
          });
        }
      });
    }
    function fechar()
    {
      dialog.value = false;
      // this.loadingTable = false;
      indexEdicao.value = -1;
      estado.value = {
        nome: "",
        uf: "",
        ibge: 0,
        sl: 0,
        ddd: 0,
      };
    }
    function editar(item)
    {
      loadings.value.editar[item.id] = true;
      if (item.updated_at == null || item.updated_at == undefined) {        
        let url = `/estados/find-by-uuid/${item.uuid}`
        this.$store
          .dispatch("resDefinicoes/findByUuid", url)
          .then((response) => {
            indexEdicao.value = data.value.estados.indexOf(item);
            Object.assign(data.value.estados[indexEdicao.value], response.data);
            estado.value = Object.assign({}, response.data);
            nameForm.value = "Editar estado: " + estado.value.nome;
            dialog.value = true;
          })
          .catch(() => {
            store.dispatch("snackBarModule/openSnackBar", {
              color: "danger",
              timeout: 3000,
              text: "Houve um erro ao buscar todos os dados do grupo selecionado.",
            });            
          })
          .finally(() => {
            loadings.value.editar[item.id] = false;
          });
      } else {
        nameForm.value = "Editar estado: " + item.nome;
        indexEdicao.value = data.value.estados.indexOf(item);
        estado.value = Object.assign({}, item);        
        dialog.value = true;
      }
      loadings.value.editar[item.id] = false;
    }
    function remover(item) {
      const index = data.value.estados.indexOf(item);
      item.url = `/estados/${item.uuid}`;
      item._method = "DELETE";
      this.$swal
        .fire({
          title: "Apagar",
          text: `Tem certeza que deseja apagar o estado: ${item.nome} ?`,
          icon: "warning",
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonColor: "#1976d2",
          confirmButtonText: "Sim, quero apagar!",
          cancelButtonColor: "#555",
          cancelButtonText: "Cancelar",
        })
        .then((response) => {
          if (response.isConfirmed) {
            loadings.value.excluir[item.id] = true;
            store
              .dispatch("resDefinicoes/remover", item)
              .then((response) => {
                if (response != "NAO_AUTORIZADO") {
                  data.value.estados.splice(index, 1);
                  this.$swal.fire({
                    text: `Item apadado com sucesso.`,
                    icon: "sucess",
                    showCloseButton: true,
                    confirmButtonColor: "#1976d2",
                    confirmButtonText: "Fechar",
                  });
                }
              })
              .catch(() => {
                this.$swal.fire({
                  text: `Houve um erro ao apagar o estado.`,
                  icon: "error",
                  showCloseButton: true,
                  confirmButtonColor: "#1976d2",
                  confirmButtonText: "Fechar",
                });
              })
              .finally(() => {
                loadings.value.excluir[item.id] = false;
              });
          }
        });
    }
    function buscarCidades(uuid) {
      loadingCidades.value[uuid] = true;
      isDialogLoadVisible.value = true;
      let url = `/estados/${uuid}`;
      store
        .dispatch("resDefinicoes/show", url)
        .then((response) => {
          console.log(response);
        })
        .catch(() => {
          store.dispatch("snackBarModule/openSnackBar", {
            color: "danger",
            timeout: 3000,
            text: "Houve um erro ao buscar cidades deste estado.",
          });
        })
        .finally(() => {
          loadingCidades.value[uuid] = false;
          isDialogLoadVisible.value = false;
        });
    }
    
    return {
      modalLateral,
      headersModal,
      toggleModalLateral,
      headers,
      data,
      loadingGeral,
      estado,
      nameForm,
      dialog,
      loadings,
      isComposeDialogVisible,
      salvar,
      fechar,
      editar,
      remover,
      buscarCidades,
      // 3rd Party
      PerfectScrollbar,
    };
  },
};
</script>
    
    <style>
</style>