<template>
  <v-card tile>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Estado: {{ estadoItem.nome }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text class="my-auto" @click="closeModalCidade">
          Fechar
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-row>
      <v-col cols="12">
        <crud
          :title="'Gestão de cidades'"
          buttonAddItemText="Novo item"
          :tamanhoModalCrud="500"
          searchLabelText="Pesquisar na lista"
          :formTitle="nameForm"
          :callBackModalCancelar="fechar"
          :dialog.sync="dialog"
          :callBackModalSalvar="salvar"
        >
          <template slot="data-table">
            <v-data-table
              :headers="headers"
              :items="cidades"
              :loading="isLoadingTable"
              class="table-kitchen-sink"
              items-per-page-text="Linhas por página"
              no-results-text="Nenhum registro encontrado"
              loading-text="Carregando... Por favor, espere"
              :footer-props="{
                showFirstLastPage: true,
                'items-per-page-text': 'Linhas por página',
              }"
              mobile-breakpoint="0"
            >
              <!-- ACTIONS -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list v-if="$can('view', 'Cliente')">
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon
                                class="actions"
                                @click="toggleModalLateral(item)"
                              >
                                mdi-eye
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Visualizar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <!-- Editar -->
                  <v-list v-if="$can('edit', 'Cliente')">
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              :loading="loadings.editar[`${item.id}`]"
                            >
                              <v-icon class="actions" @click="editar(item)">
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <!-- Deletar -->
                  <v-list v-if="$can('delete', 'Cliente')">
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              :loading="loadings.excluir[`${item.id}`]"
                            >
                              <v-icon
                                class="actions"
                                @click="remover(item, true)"
                              >
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Deletar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ item.created_at | dateFormatBr() }}
              </template>
              <template v-slot:no-data>
                <v-alert :value="true" color="error">
                  Nenhum registro encontrado
                </v-alert>
              </template>
            </v-data-table>
          </template>
          <template slot="contentModalCrud">
            <v-container fluid>
              <v-row class="ma-3" wrap>
                <!-- Nome -->
                <v-col cols="12" sm="12">
                  <v-text-field
                    ref="nome"
                    label="Nome"
                    placeholder="Escreva o nome do cidade"
                    required
                    outlined
                    dense
                    v-model="cidade.nome"
                    data-vv-as="nome"
                    persistent-hint
                    :hint="errors.first('nome')"
                    :error="errors.collect('nome').length ? true : false"
                    v-validate="'required|min:3'"
                    data-vv-validate-on="change"
                    id="nome"
                    name="nome"
                  ></v-text-field>
                </v-col>
                <!-- IBGE -->
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    ref="ibge"
                    label="IBGE"
                    placeholder="Escreva o código do cidade"
                    required
                    outlined
                    dense
                    v-model="cidade.ibge"
                    data-vv-as="ibge"
                    persistent-hint
                    :hint="errors.first('ibge')"
                    :error="errors.collect('ibge').length ? true : false"
                    v-validate="'required'"
                    data-vv-validate-on="change"
                    id="ibge"
                    name="ibge"
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <!-- ------------- MODAL DE DELETE --------------------------------------------- -->
          <template slot="modalDelete/titleModal">Excluir o registro?</template>
          <template slot="modalDelete/contentModal">
            <p>
              Atenção! Você está prestes a realizar uma ação que não pode ser
              desfeita.
            </p>
            <p>Você realmente deseja excluir esse registro?</p>
          </template>
          <template slot="modalDelete/actionsModal">
            <v-btn depressed>Fechar</v-btn>
            <v-btn color="error">Excluir</v-btn>
            <v-spacer class="d-none d-md-block"></v-spacer>
          </template>
        </crud>
      </v-col>
    </v-row>
  </v-card>
</template>
  
  <script>
import { ref } from "@vue/composition-api";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import Crud from "@/components/Crud.vue";
import ModalLateral from "@/components/ModalLateral.vue";

export default {
  inject: ["$validator"],
  props: {
    isDialogFullscreen: {
      type: Boolean,
      default: false,
    },
    isLoadingTable: {
      type: Boolean,
      default: false,
    },
    estadoItem: {
      type: Object,
      required: true,
    },
    cidades: {
      type: Array,
      required: true,
    },
  },
  components: {
    // 3rd Party
    PerfectScrollbar,
    Crud,
    ModalLateral,
  },
  setup(_, { emit }) {
    const modalLateral = ref({
      show: false,
      item: [],
      title: "",
    });
    const headersModal = ref(
      {
        text: "Nome",
        value: "name",
      },
      {
        text: "Local",
        value: "guard_name",
      },
      {
        text: "Permissões",
        value: "permissoes",
      },
      {
        text: "Data de criação",
        value: "created_at",
      },
      {
        text: "Última alteração",
        value: "updated_at",
      }
    );
    const toggleModalLateral = ref();
    const headers = ref([
      { text: "Nome", value: "nome" },
      { text: "IBGE", value: "ibge" },
      { text: "Data de criação", value: "created_at" },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ]);
    const cidade = ref({
      nome: "",
      ibge: "",
    });
    const dialog = ref(false);
    const indexEdicao = ref(-1);
    const loadings = ref({
      editar: [],
      excluir: [],
    });
    const nameForm = ref("Nova cidade");
    const dadosModulo = ref({
      url: "cidades/initialize",
      set: "setDataCidades",
      method: {
        post: "POST",
        put: "PUT",
      },
      post: "/post",
      put: "/cidades/",
      sucesso: {
        salvar: "Cidade cadastrado com sucesso.",
        atualizar: "Cidade atualizado com sucesso.",
      },
      erro: {
        salvar: "Erro ao salvar cidade.",
        atualizar: "Erro ao atualizar cidade.",
      },
    });

    function salvar() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          dialog.value = false;
          let sucesso =
            indexEdicao.value === -1
              ? dadosModulo.value.sucesso.salvar
              : dadosModulo.value.sucesso.atualizar;
          let erro =
            indexEdicao.value === -1
              ? dadosModulo.value.erro.salvar
              : dadosModulo.value.erro.atualizar;
          let cidadeItem = estado.value;
          cidadeItem._method =
            indexEdicao.value === -1
              ? dadosModulo.value.method.post
              : dadosModulo.value.method.put;
          cidadeItem.url =
            indexEdicao.value === -1
              ? dadosModulo.value.post
              : dadosModulo.value.put + estado.value.uuid;
          cidadeItem.id_estado = _.estadoItem.id;
          store
            .dispatch("resDefinicoes/salvar", cidadeItem)
            .then((response) => {
              if (response === true) {
                indexEdicao.value === -1
                  ? data.value.estados.push(cidadeItem)
                  : Object.assign(
                      data.value.estados[indexEdicao.value],
                      data.value.estados
                    );
                store.dispatch("snackBarModule/openSnackBar", {
                  color: "success",
                  timeout: 3000,
                  text: sucesso,
                });
              }
            })
            .catch((error) => {
              console.log(error);
              store.dispatch("snackBarModule/openSnackBar", {
                color: "danger",
                timeout: 3000,
                text: erro,
              });
            })
            .finally(() => {
              fechar();
            });
        } else {
          store.dispatch("snackBarModule/openSnackBar", {
            color: "danger",
            timeout: 3000,
            text: "Preencha todo formulário antes de salvar!",
          });
        }
      });
    }
    function editar(item) {
      loadings.value.editar[item.id] = true;
      if (item.updated_at == null || item.updated_at == undefined) {
        let url = `/cidades/find-by-uuid/${item.uuid}`;
        this.$store
          .dispatch("resDefinicoes/findByUuid", url)
          .then((response) => {
            indexEdicao.value = props.cidades.value.indexOf(item);
            Object.assign(
              props.cidades.value[indexEdicao.value],
              response.data
            );
            cidade.value = Object.assign({}, response.data);
            nameForm.value = "Editar cidade: " + cidade.value.nome;
            dialog.value = true;
          })
          .catch(() => {
            store.dispatch("snackBarModule/openSnackBar", {
              color: "danger",
              timeout: 3000,
              text: "Houve um erro ao buscar dados da cidade selecionada.",
            });
          })
          .finally(() => {
            loadings.value.editar[item.id] = false;
          });
      } else {
        nameForm.value = "Editar cidade: " + item.nome;
        indexEdicao.value = _.cidades.indexOf(item);
        cidade.value = Object.assign({}, item);
        dialog.value = true;
      }
      loadings.value.editar[item.id] = false;
    }
    function fechar() {
      dialog.value = false;
      indexEdicao.value = -1;
      cidade.value = {
        nome: "",
        ibge: "",
      };
    }
    function remover(item) {
      const index = _.cidades.indexOf(item);
      item.url = `/cidades/${item.uuid}`;
      item._method = "DELETE";
      this.$swal
        .fire({
          title: "Apagar",
          text: `Tem certeza que deseja apagar a cidade: ${item.nome} ?`,
          icon: "warning",
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonColor: "#1976d2",
          confirmButtonText: "Sim, quero apagar!",
          cancelButtonColor: "#555",
          cancelButtonText: "Cancelar",
        })
        .then((response) => {
          if (response.isConfirmed) {
            loadings.value.excluir[item.id] = true;
            store
              .dispatch("resDefinicoes/remover", item)
              .then((response) => {
                if (response != "NAO_AUTORIZADO") {
                  data.value.estados.splice(index, 1);
                  this.$swal.fire({
                    text: `Item apagado com sucesso.`,
                    icon: "sucess",
                    showCloseButton: true,
                    confirmButtonColor: "#1976d2",
                    confirmButtonText: "Fechar",
                  });
                }
              })
              .catch(() => {
                this.$swal.fire({
                  text: `Houve um erro ao apagar o estado.`,
                  icon: "error",
                  showCloseButton: true,
                  confirmButtonColor: "#1976d2",
                  confirmButtonText: "Fechar",
                });
              })
              .finally(() => {
                loadings.value.excluir[item.id] = false;
              });
          }
        });
    }
    const closeModalCidade = () => {
      emit("update:isDialogFullscreen", false);
      emit("update:cidades", []);
      emit("update:estadoItem", {});
      emit("close-dialog");
      cidade.value = {};
    };

    return {
      modalLateral,
      headersModal,
      toggleModalLateral,
      headers,
      loadings,
      cidade,
      nameForm,
      dialog,
      salvar,
      fechar,
      editar,
      remover,
      closeModalCidade,
    };
  },
};
</script>
  